.custom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

  .lightBox{
    width: 70px;
    min-width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
  }
}

.item{
  margin: 15px;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  height: calc(100% - 30px);
  border: 1px solid #FCFCFC;
  box-sizing: border-box;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.05);
  //padding-bottom: 40px;
  :global{
    .photo{
      position: relative;
      height: 250px;
      overflow: hidden;
      img{
        position: absolute;
        object-fit: cover;
        width: 100%;
      }
      .custom-link{
        img{
          height: calc(100% + 135px);
        }
      }
      .days-left{
        position: absolute;
        right: 5px;
        bottom: 5px;
        background: #fff;
        height: 30px;
        border: 1px solid #F2C94C;
        border-radius: 3px;
        padding: 3px 5px;
        line-height: 24px;
        font-size: 16px;
        color: #333333;

        img{
          position: relative;
          object-fit: none;
          width: auto;
          margin-right: 3px;
          margin-top: -5px;
        }
      }
      .not-approved{
        position: absolute;
        left: 0;
        top: 0;
        background: #28A745;
        color: #fff;
        padding: 3px 10px;
        border-bottom-right-radius: 4px;
        font-weight: 600;
        font-size: 16px;
      }
    }
    .youtube-link{
      color: #DC3545;
      border-color: #DC3545;
      background: #fff;
      padding: 1px 5px 2px;
      border-radius: 3px;
      margin-left: 5px;
      height: 21px;
      display: inline-block;
      &:hover{
        color: #fff;
        background: #DC3545;
        border-color: #DC3545;
      }
    }
    .content{
      padding: 15px;
      min-height: 180px;
      &__title{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        margin-bottom: 10px;
        &_date{
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }
        &_city{
          font-weight: 500;
          font-size: 14px;
          color:#1b785d;
          line-height: 17px;
        }
      }
      &__description{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 10px;
      }
      &__type{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
        margin-bottom: 10px;
      }
      &__admin{
        padding-top: 20px;
        padding-bottom: 30px;
      }
      &__status{
        padding: 5px 0 0;
        font-size: 14px;
        color: #333333;
        &_done-wrap{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .reports-links{
            display: flex;
            align-items: center;
            .custom-link {
              cursor: pointer;
              font-weight: 700;
              color: #F8C600;
              background: #FEF7D9;
              border: 1px solid #F8C600;
              padding: .375rem 1.25rem;
              box-sizing: border-box;
              border-radius: 4px;
              text-decoration: none;

              img {
                margin-right: 7px;
              }
            }
          }
        }
        .custom-btn{
          text-transform: uppercase;
          &__free{
            background: #0E785D;
            color: #FFFFFF;
            border-radius: 4px;
            font-weight: bold;
            border-color: transparent;
            &:hover{
              color: #27AE60;
              background: #fff;
              border-color: #27AE60;
            }
          }
          &__booked{
            color: #828282;
            background: #fff;
            border: unset;
            padding-left: 0;
            min-width: unset;

          }
          &__confirmed{
            color: #17A2B8;
            background: #fff;
            border: unset;
            padding-left: 0;
            min-width: unset;
          }
          &__done{
            color: #0062CC;
            background: #fff;
            border: unset;
            padding-left: 0;
            min-width: unset;
          }
        }

        &_free{
          font-weight: 600;
          color: #219653;
        }
        &_booked{
          font-weight: 600;
          color: #828282;
        }
        &_confirmed{
          font-weight: 600;
          color: #17A2B8;
        }
        &_done{
          font-weight: 600;
          color: #0062CC;
        }
      }
      &__create-at{
        position: absolute;
        height: 21px;
        color: #828282;
        left: 30px;
        bottom: 30px;
        font-size: 14px;
      }
      &__request{
        position: absolute;
        height: 21px;
        right: 25px;
        bottom: 30px;
        font-size: 14px;
        display: flex;
      }
    }
  }
}


