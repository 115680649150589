.title{
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #114869;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0;
  @media screen and (max-width: 769px) {
    font-size: 29px;
    line-height: 38px;
  }
}
.text{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #333333;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0;
}
.video{
  width: 100%;
}
.photo{
  width: 100%;
}
.information_card{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  margin: 10px 0;
}

.block {
  display: flex;
  align-items: center;
  @media screen and (max-width: 769px) {
    display: block;
  }
}