.main {
  background: #FFFFFF;
  padding-top: 46px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  h1{
    font-size: 30px;
    margin-top: 10px;
  }

  :global{
    .ant-breadcrumb {
      padding: 15px 0 0 0;
      @media screen and (max-width: 768px) {
        padding: 30px 0 0 0;
      }
    }
  }
}
.content {
  padding: 0 50px;
  :global {
    .roles {
      padding: 30px 0;
      font-size: 18px;
      @media screen and (max-width: 769px) {
        font-size: 15px;
      }

      &__description {
        align-items: center;
        margin-bottom: 20px;

        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #0076C2;
          @media screen and (max-width: 769px) {
            font-size: 20px;
          }
        }

        p {
          line-height: 30px;
          color: #333333;

          .select {
            color: #0076C2;
          }
        }
      }

      &__participation {
        align-items: center;
        margin-bottom: 10px;

        h2 {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #28A745;
          @media screen and (max-width: 769px) {
            font-size: 20px;
          }
        }

        ol {
          padding-left: 16px;
          line-height: 24px;

          li {
            margin-bottom: 10px;
          }
        }
      }

      &__payment {
        line-height: 24px;

        &_card {
          font-weight: 600;
        }
      }

      .custom-link {
        color: #0076C2;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}