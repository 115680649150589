 .rules_title{
    font-family: Cormorant Garamond;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    color: #114869;
    margin: 40px 0;
  }
  .rules{
    display: flex;
    align-items: center;
    p{
      margin: 0.5rem;
      padding: 0;
      span, .link{
        font-weight: unset !important;
        text-decoration-line: underline !important;
        color: #114869 !important;
      }
    }
    .number_rules{
      font-family: Cormorant Garamond;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 150%;
      color: #114869;
      margin: 0 16px;
    }
    .list_rules{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #333333;
    }
  }