.wrap {
  background: #FFFFFF;
  height: 46px;
  box-shadow: 0 4px 32px rgba(53, 53, 53, 0.08);
  position: fixed;
  width: 100%;
  z-index: 1000;
  @media screen and (max-width: 768px) {
    position: relative;
    height: auto;
    padding: 20px 0 0 0;
    border-bottom: none;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  @media (min-width: 1440px) {
    min-width: 1440px;
    margin: 0 auto;
    height: 46px;
  }
  @media screen and (max-width: 768px) {
    display: block;
    text-align: center;
  }

  :global {
    .ant-menu {
      background: transparent;
    }
    .ant-menu-horizontal {
      line-height: 43px;
      border-bottom: unset;
      @media screen and (max-width: 768px) {
        border-bottom: 1px solid transparent;
        white-space: normal;
      }
    }


    .menu-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 60px;
      cursor: pointer;
      transition: all .5 ease-in-out;
    }

    .menu-btn__burger {
      background: #6C757D;
      width: 30px;
      height: 3px;
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
      transition: all .5s ease-in-out;
    }

    .menu-btn__burger::before,
    .menu-btn__burger::after {
      content: '';
      position: absolute;
      background: #6C757D;
      width: 30px;
      height: 3px;
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
      transition: all .5s ease-in-out;
    }

    .menu-btn__burger::before {
      transform: translate(-15px, -10px);
    }

    .menu-btn__burger::after {
      transform: translate(-15px, 10px);
    }

    .ant-menu-submenu-open {
      .menu-btn .menu-btn__burger {
        transform: translateX(-50px);
        background: transparent;
        box-shadow: none;

        &::before {
          transform: rotate(45deg) translate(35px, -35px);
        }

        &::after {
          transform: rotate(-45deg) translate(35px, 35px);
        }
      }
    }

    .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
      border-color: transparent;
      color: #114869;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        border-color: transparent;
      }
      a {
        color: #114869;
      }
    }

    .menu {
      &__left {
        display: flex;
        align-items: center;

        .ant-menu-item, .ant-menu-submenu-title {
          padding: 0;
          margin: 0 20px;
          @media screen and (max-width: 768px) {
            margin: 0 20px 0 0;
            padding: 0;
          }
        }

        @media screen and (max-width: 768px) {
          display: block;
          text-align: left;
          margin: 0 20px;
          > .ant-menu-item:nth-child(1) {
            margin-right: 150px;
          }
        }

        .ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-overflowed-submenu {
          @media screen and (max-width: 768px) {
            position: absolute;
            top: 20px;
            right: 20px;
            .ant-menu-submenu-title {
              margin: 0 5px;
            }
          }
        }
      }

      &__center {
        margin-left: 100px;

        .ant-menu-item {
          a {
            min-width: 130px;
            text-align: center;
            color: #114869;

          }
        }
        @media screen and (max-width: 768px) {
          position: absolute;
          top: 17%;
          right: 3%;
        }
      }

      &__right {
        margin: 0 10px 0 0;
        @media screen and (max-width: 768px) {
          text-align: center;
          margin: 0 20px;
        }

        .ant-menu-item, .ant-menu-submenu-title {
          padding: 0;
          margin: 0 10px;
          @media screen and (max-width: 768px) {
            margin: 0 20px 0 0;
            padding: 0;
          }
        }

        a.social-link {
          line-height: 1.7;

          &:hover {
            opacity: 0.8;
          }

        }
      }
    }

    .logo {
      color: rgba(0, 0, 0, 0.9);
      line-height: 1;
    }
  }
}