@import url('https://fonts.googleapis.com/css?family=Cormorant+Infant:400,400i,500,500i,600,600i,700,700i&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&family=Lato:wght@100;300;400;700;900&display=swap');
.volunteer {
  background-size: cover;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.ant-menu{
  font-size: 16px;
}

.ant-layout{
  background: #fff;
}

.ant-modal, .ant-btn, .ant-modal-body, .ant-form label, .ant-breadcrumb, .ant-layout-footer, .ant-select, .ant-select-dropdown, .ant-select-dropdown-menu-item, .ant-calendar, .ant-input, .ant-checkbox-wrapper {
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

a.ant-btn {
  line-height: 34px;
}

.ant-modal-title, .ant-drawer-title {
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.ant-form-item {
  margin-bottom: 0;
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.gallery-counter {
  position: absolute;
  top: 0;
  left: 30px;
}

.ant-pagination-item {
  margin: 0 4px;
}

.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  margin-right: 4px;
}

.ant-pagination-next{
  margin-left: 4px;
}

.custom-btn {
  min-width: 140px;
  padding: 0 15px;
  height: 34px;
  border-radius: 20px;

  &__consent {
    color: #fff;
    background: #0062CC;
    border-color: #0062CC;

    &:hover {
      color: #0062CC;
      background: #fff;
      border-color: #0062CC;
    }
  }

  &__action {
    color: #fff;
    background: #28A745;
    border-color: #28A745;

    &:hover {
      color: #28A745;
      background: #fff;
      border-color: #28A745;
    }
  }

  &__request {
    color: #28A745;
    background: #fff;
    border-color: #28A745;

    &:hover {
      color: #fff;
      background: #28A745;
      border-color: #28A745;
    }
  }

  &__cancel {
    color: #fff;
    background: #6C757D;
    border-color: #6C757D;

    &:hover {
      color: #6C757D;
      background: #fff;
      border-color: #6C757D;
    }
  }

  &__remove {
    color: #fff;
    background: #DC3545;
    border-color: #DC3545;

    &:hover {
      color: #DC3545;
      background: #fff;
      border-color: #DC3545;
    }
  }
}

.ant-drawer-content-wrapper {
  @media screen and (max-width: 769px) {
    max-width: 100%;
  }
}

.top-admin {
  @media screen and (max-width: 769px) {
    div:last-child {
      padding-top: 1rem;

      .form-control {
        min-width: 150px;
      }
    }
  }
}

.fund-photo {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  border: 10px solid #ffffff;
  margin: 1rem 0;
  width: 100%;
}

.filter {
  .col-auto {
    border-radius: 4px;
  }
}

.card {
  border-radius: 0;
  margin-top: -1px;

  .btn {
    min-width: 125px;
  }
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
}

.table {
  thead {
    tr {
      th {
        white-space: nowrap;

        &:nth-child(3) {
          width: 250px;
          @media screen and (max-width: 769px) {
            width: auto;
          }
        }

        &:nth-child(4) {
          width: 250px;
          @media screen and (max-width: 769px) {
            width: auto;
          }
        }

        &:last-child {
          width: 215px;
          @media screen and (max-width: 769px) {
            width: auto;
          }
        }
      }
    }
  }
}

.modal-footer {
  .btn {
    min-width: 125px;
  }
}

@media screen and (max-width: 769px) {
  body {
    font-size: 0.8rem;
  }
  .navbar-toggler {
    display: none;
  }
  .btn {
    font-size: 0.75rem;
  }
  .navbar-brand {
    font-size: 1rem;
  }
  .display-4 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  .pagination {
    justify-content: center;

    .page-link {
      padding: .25rem .5rem;
      font-size: .875rem;
      line-height: 1.5;
    }
  }

  .collapse:not(.show) {
    display: flex;
    flex-basis: inherit;
  }
  .navbar-nav {
    flex-direction: row;

    .nav-item {
      margin-left: 0.5rem;

      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
  .form-control {
    font-size: inherit;
  }

}
