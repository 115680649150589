@import "src/app/css/mixins/index";

.title {
  font-family: 'Cormorant Garamond';
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 32px;
  color: #114869;
  text-align: right;
  @include devices(md) {
    text-align: center;
  }
}

.text {
  font-size: 18px;
  margin-bottom: 40px;
  color: #114869;
  text-align: right;
  @include devices(md) {
    text-align: left;
  }
}


.image1Container {
  position: relative;
  max-width: 384px;
  margin: 10px auto 40px;
  &:before {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    left: -40px;
    bottom: -40px;
    background: rgba(10, 104, 214, 0.2);
    border-radius: 4px;
    @include devices(md) {
      display: none;
    }
  }

}

.image2Container {
  position: relative;
  text-align: right;
  margin: 55px 0 100px;
  @include devices(md) {
    margin: 0 0 20px;
    text-align: center;
  }
  &:before {
    content: '';
    position: absolute;
    width: 518px;
    height: 500px;
    right: -35px;
    top: -35px;
    background: rgba(253, 199, 11, 0.64);
    border-radius: 4px;
    @include devices(md) {
      display: none;
    }
  }
}

.img1 {
  position: relative;
  border-radius: 4px;
  width: 100%;
  max-width: 384px;
}

.img2 {
  position: relative;
  border-radius: 4px;
  width: 100%;
  max-width: 653px;

}



