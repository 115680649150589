.wrap {
  background: #FFFFFF;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 4px 32px rgba(53, 53, 53, 0.08);
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.main {
  padding: 20px 0 10px;
  max-width: 1400px;
  @media (min-width: 1400px) {
    min-width: 1400px;
    margin: 0 auto;
  }
  @media screen and (max-width: 769px) {
    text-align: center;
  }

  :global {
    .ant-row-flex > .ant-col:last-child {
      @media (min-width: 769px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .footer-right-block {
      img{
        margin-left: 5px;
      }
      > div {
        display: flex;
        align-items: center;
        @media (max-width: 769px) {
          justify-content: center;
        }

        img {
          margin-left: 5px;
        }

      }
    }
    .social-block{
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        justify-content: center;
      }
    }
    .footer_contacts{
      display: flex;
      align-items: center;
      .name_contact{
        padding-left: 5px;
      }
      .number_contact{
        padding-left: 10px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        justify-content: center;
        .name_contact{
          padding-left: 5px;
        }
        .social-link{
          margin-left: 10px;
          width: 21px;
          .icon-facebook{
            width: 100%;
          }

        }
        .number_contact{
          padding-left: 4px;
          display: none;
        }
      }
    }

    a.social-link {
      line-height: 1;
      margin-left: 10px;
      &:hover{
        opacity: 0.8;
      }


      .icon-facebook {
        fill: #173CA1;
      }

      .icon-viber {
        fill: #751FB8;
      }


    }
  }

}
