.container {
  font-family: Cormorant Garamond;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #114869;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0;
  @media screen and (max-width: 769px) {
    font-size: 29px;
    line-height: 38px;
  }
}